import api from './api';

const indexService = {
    login: (user, password) => {
        return api.post('/login', { usuario: user, password: password});
    },
    logout: () => {
        return api.put('/logout');
    }
};

export default indexService;