import api from './api';

const multimediaService = {
    getList: (params) => {
        return api.get('/multimedia', params || undefined, true);
    },
    getOne: (id) => {
        return api.get('/multimedia/'+id)
    },
    create: (data) => {
        return api.post('/multimedia', data, true)
    },
    // update: (id, data) => {
    //     return api.put('/multimedia/'+id, data)
    // },
    updateName: (id, newName) => {
        return api.put('/multimedia/'+id, { nombre: newName }, false, true)
    },
    delete: (id) => {
        return api.delete('/multimedia/'+id)
    },
};

export default multimediaService;