import api from './api';

const adminService = {
    getList: () => {
        return api.get('/administrador');
    },
    getOne: (id) => {
        return api.get('/administrador/'+id)
    },
    create: (data) => {
        return api.post('/administrador', data)
    },
    update: (id, data) => {
        return api.put('/administrador/'+id, data)
    },
    delete: (id) => {
        return api.delete('/administrador/'+id)
    },
    getListPermisos: () => {
        return api.get('/administrador/permiso')
    },
};

export default adminService;