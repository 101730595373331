import { appRoutes } from 'src/utils';
import api from './api';

const eventoService = {
    getList: (params) => {
        return api.get('/evento', params || undefined);
    },
    getOne: (id) => {
        return api.get('/evento/'+id)
    },
    create: (data) => {
        return api.post('/evento', data)
    },
    update: (id, data) => {
        return api.put('/evento/'+id, data)
    },
    delete: (id) => {
        return api.delete('/evento/'+id)
    },
    publicar: (id) => {
        return api.put('/evento/'+id+'/publicar')
    },
    noPublicar: (id) => {
        return api.put('/evento/'+id+'/no-publicar')
    },
    searchClients: (term, limit) => {
        return api.get('/evento/clientes', { term: term, limit: limit || 20 })
    },
    getTiposEventos: () => {
        return api.get('/evento/tipos-evento')
    },
    getInvitaciones: (eventoId, params) => {
        return api.get('/evento/'+eventoId+'/invitacion', params)
    },
    createInvitacion: (eventoId, data) => {
        return api.post('/evento/'+eventoId+'/invitacion', data)
    },
    updateInvitacion: (eventoId, invitacionId, data) => {
        return api.put('/evento/'+eventoId+'/invitacion/'+invitacionId, data)
    },
    confirmarInvitacion: (eventoId, invitacionId, dataToSend) => {
        return api.put('/evento/'+eventoId+'/invitacion/'+invitacionId+'/confirmar', dataToSend)
    },
    deleteInvitacion: (eventoId, invitacionId) => {
        return api.delete('/evento/'+eventoId+'/invitacion/'+invitacionId)
    },
    getUrlPublicaInvitaciones: (eventoId) => {
        return api.get('/evento/'+eventoId+'/invitacion/url')
    },
    getReporteInvitacionesExcel: (eventoId) => {
        return api.get( '/evento/'+eventoId+'/invitacion/reporte')
    },
    getOneInvitacion: (eventoId, invitacionId) => {
        return api.get( '/evento/'+eventoId+'/invitacion/'+invitacionId)
    },
    
    // manejo de bloques de la presentacion
    getSeccionesEditor: (eventoId) => {
        return api.get('/evento/'+eventoId+'/presentacion/editor')
    },
    getPresentacion: (eventoId) => {
        return api.get('/evento/'+eventoId+'/presentacion')
    },
    savePresentacion: (eventoId, data) => {
        return api.put('/evento/'+eventoId+'/presentacion', data, false, true)
    },
    // createSeccion: (eventoId, data) => {
    //     return api.post('/evento/'+eventoId+'/seccion')
    // },
    // updateSeccion: (eventoId, seccionId, data) => {
    //     return api.put('/evento/'+eventoId+'/seccion/'+seccionId, data)
    // },
    // deleteSeccion: (eventoId, seccionId) => {
    //     return api.delete('/evento/'+eventoId+'/seccion/'+seccionId)
    // },

    getMenu: (eventoId) =>{
        return api.get(`/evento/${eventoId}/menu`)
    },
    saveMenu: (eventoId, data) => {
        return api.put(`/evento/${eventoId}/menu`, data)
    },
    
};

export default eventoService;