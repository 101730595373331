import api from './api';

const clienteService = {
    getList: (params) => {
        return api.get('/cliente', params || undefined);
    },
    getOne: (id) => {
        return api.get('/cliente/'+id)
    },
    create: (data) => {
        return api.post('/cliente', data)
    },
    update: (id, data) => {
        return api.put('/cliente/'+id, data)
    },
    delete: (id) => {
        return api.delete('/cliente/'+id)
    },
};

export default clienteService;