import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

export default {
	info: (title, message) => {
        Swal.fire({title: title, text: message, icon: 'info'})
    },
	infoWithComponent: (title, message) => {
        
        let MySwal = withReactContent(Swal)

        MySwal.fire({
            title: title, 
            html: message, 
            icon: 'info'
        })
    },
	warning: (title, message) => {
        Swal.fire({title: title, text: message, icon: 'warning'})
    },
	error: (title, message) => {
        Swal.fire({title: title, text: message, icon: 'error'})
    },
	success: (title, message) => {
        Swal.fire({title: title, text: message, icon: 'success'})
    },
    confirm: (title, message, cb) => {
        Swal.fire({
            title: title, 
            text: message, 
            icon: 'question', 
            reverseButtons: true,
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
        }).then(result => {
            cb(result.isConfirmed)
        }, (reason) => {
            cb(false)
        })
    },
    confirmWithComponent: (title, html, cb) => {
        let MySwal = withReactContent(Swal)

        MySwal.fire({
            title: title, 
            html: html, 
            icon: 'question', 
            reverseButtons: true,
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
        }).then(result => {
            cb(result.isConfirmed)
        }, (reason) => {
            cb(false)
        })
    }
};