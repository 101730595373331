const prefix = 'adm_eventos_digitales';

var _getModulos = (completo) => {
	try{
		var strMods = localStorage.getItem(prefix+'_usuario_modulos');
		if(strMods &&  strMods != "undefined"){
			var mods = JSON.parse(strMods) || [];
			return completo ? mods : mods.map((item)=>{ return item.identificador; });
		}else{
			return [];
		}
	}catch(error){
		console.log(error);
		return [];
	}
};

export default{
	clear: function(){
		// localStorage.clear();
		localStorage.removeItem(prefix+'_usuario_nombre');
		localStorage.removeItem(prefix+'_usuario_primer_apellido');
		localStorage.removeItem(prefix+'_usuario_segundo_apellido');
		localStorage.removeItem(prefix+'_usuario_id');
		localStorage.removeItem(prefix+'_usuario_token');
		localStorage.removeItem(prefix+'_usuario_imagen');
		localStorage.removeItem(prefix+'_usuario_modulos');
	},
	start: function(data){
		localStorage.setItem(prefix+'_usuario_nombre', data.nombre || '');
		localStorage.setItem(prefix+'_usuario_primer_apellido', data.primer_apellido || '');
		localStorage.setItem(prefix+'_usuario_segundo_apellido', data.segundo_apellido || '');

		localStorage.setItem(prefix+'_usuario_id', data.id || '');
		localStorage.setItem(prefix+'_usuario_token', data.token || data.claveApi || '');
		localStorage.setItem(prefix+'_usuario_imagen', data.imagen || '');
		localStorage.setItem(prefix+'_usuario_modulos', data.modulos ? JSON.stringify(data.modulos) : null );
	},
	setModulos: function(mods){		
		localStorage.setItem(prefix+'_usuario_modulos', mods ? JSON.stringify(mods) : '');
	},
	getModulos: _getModulos,
	tieneModulo: (identifier) => {
		return _getModulos(false).indexOf(identifier) !== -1;
	},
	get: function(key, isObj){
		var val = localStorage.getItem(prefix+'_usuario_'+key) || null;
		return (val === "null" || val === "undefined") ? null : ( isObj ? JSON.parse(val) : val);
	},
	exists: function(){
		return localStorage.getItem(prefix+'_usuario_id') != null;
	},
	authToken: function(){
		return localStorage.getItem(prefix+'_usuario_token') || '';
	},
	set: function(key, value, isObj){
		localStorage.setItem(prefix+'_usuario_'+key, isObj ? JSON.stringify(value) : value);
	},
	setFlashValue: (key, value) => {
		sessionStorage.setItem(key, value || '');
	},
	getFlashValue: (key, value) => {
		let v = sessionStorage.getItem(key) || (value != undefined ? value : '');
		sessionStorage.removeItem(key);
		return v;
	}
};