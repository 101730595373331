import api from './api';

const permisoService = {
    getList: () => {
        return api.get('/permiso');
    },
    getOne: (id) => {
        return api.get('/permiso/'+id)
    },
    create: (data) => {
        return api.post('/permiso', data)
    },
    update: (id, data) => {
        return api.put('/permiso/'+id, data)
    },
    delete: (id) => {
        return api.delete('/permiso/'+id)
    },
};

export default permisoService;