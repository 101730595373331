import React, { Component } from 'react';
import { HashRouter, Route, Switch, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AxiosLoader from './components/AxiosLoader/AxiosLoader';
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import { session } from './utils';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./components/pages/login/Login'));
const Register = React.lazy(() => import('./components/pages/register/Register'));
const Page404 = React.lazy(() => import('./components/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./components/pages/page500/Page500'));

class App extends Component {

  render() {
    return (
      <div>
        <AxiosLoader>
          <ToastContainer pauseOnFocusLoss={false} />
          {
            process.env.NODE_ENV == 'development' ?
            (
              <HashRouter>
                  <React.Suspense fallback={loading}>
                    <Switch>
                      <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                      <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                      <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                      <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                      <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                    </Switch>
                  </React.Suspense>
              </HashRouter>
            )
            :
            (
              <BrowserRouter>
                  <React.Suspense fallback={loading}>
                    <Switch>
                      <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                      <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                      <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                      <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                      <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                    </Switch>
                  </React.Suspense>
              </BrowserRouter>
            )
          }
        </AxiosLoader>
      </div>
    );
  }
}

export default App;
