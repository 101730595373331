import queryString from 'query-string';
import moment from 'moment'

const TestFunction = () => {
    return 'Hola a todos';
}

const ConcatNameLegacy = (obj) => {
    if(obj){
        return [obj.nombre || '', obj.apellido_paterno || '', obj.apellido_materno || ''].join(' ').trim()
    }
    return ""
}

const ConcatName = (obj) => {
    if(obj){
        return [obj.nombre || '', obj.primer_apellido || '', obj.segundo_apellido || ''].join(' ').trim()
    }
    return ""
}

const FormalEstatus = (estatus, pretty) => {
    if(estatus == 'activo') return pretty ? <span className="badge bg-success p-2 text-white">Activo</span> : 'Activo'
    if(estatus == 'inactivo') return pretty ? <span className="badge bg-danger p-2 text-white">Inactivo</span> : 'Inactivo'
    return ''
}

const getParam = function(props, name){
    return (props &&  props.match && props.match.params) ? props.match.params[name] : null;
}

const getQuery =  (props, name) => {
    const vv = props.location ? queryString.parse(props.location.search) : null;
    return vv ? vv[name] : '';
}

const SafeFormatDate = (date, format) => {
    if(date && moment(date).isValid()){
        return moment(date).format(format || 'DD/MM/YYYY')
    }
    return ''
}

const SafeFormatDateTime = (date, format) => {
    if(date && moment(date).isValid()){
        return moment(date).format(format || 'DD/MM/YYYY hh:mm a')
    }
    return ''
}

const SafeFormatTime = (date, format) => {
    if(date && moment(date).isValid()){
        return moment(date).format(format || 'hh:mm a')
    }
    return ''
}

const isValidFormikArrayField = (formik, index, array_name, field_name) => {
    // valid={formik.touched.requisitos && formik.touched.requisitos[index] && formik.touched.requisitos[index].cantidad_solicitada && (!formik.errors.requisitos || !formik.errors.requisitos[index] || !formik.errors.requisitos[index].cantidad_solicitada)}
    // invalid={(formik.touched.requisitos && formik.touched.requisitos[index] && formik.touched.requisitos[index].cantidad_solicitada && formik.errors.requisitos && formik.errors.requisitos[index] && formik.errors.requisitos[index].cantidad_solicitada) ? true : false}
    if (array_name) {
        return formik.touched[array_name] &&
            formik.touched[array_name][index] &&
            formik.touched[array_name][index][field_name] &&
            (
                !formik.errors[array_name] ||
                !formik.errors[array_name][index] ||
                !formik.errors[array_name][index][field_name]
            );
    } else {
        return !formik.errors[field_name] && formik.touched[field_name];
    }
}

const isInvalidFormikArrayField = (formik, index, array_name, field_name) => {
    if (array_name) {
        return (
            formik.touched[array_name] &&
            formik.touched[array_name][index] &&
            formik.touched[array_name][index][field_name] &&
            formik.errors[array_name] &&
            formik.errors[array_name][index] &&
            formik.errors[array_name][index][field_name]
        ) ? true : false;
    } else {
        return formik.errors[field_name] && formik.touched[field_name];
    }
}

const getErrorFormikFieldArray = (formik, index, array_name, field_name) => {
    if (isInvalidFormikArrayField(formik, index, array_name, field_name)) {
        return array_name ? formik.errors[array_name][index][field_name] : formik.errors[field_name];
    } else {
        return '';
    }
}

const ToDegreesMinutesAndSeconds = (coordinate) => {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return degrees + " " + minutes + " " + seconds;
}

const FormalLatLng = (lat, lng) => {
    var latitude = ToDegreesMinutesAndSeconds(lat);
    var latitudeCardinal = lat >= 0 ? "N" : "S";

    var longitude = ToDegreesMinutesAndSeconds(lng);
    var longitudeCardinal = lng >= 0 ? "E" : "W";

    return latitude + " " + latitudeCardinal + "\n" + longitude + " " + longitudeCardinal;
}

export default {
    TestFunction,
    ConcatNameLegacy,
    ConcatName,
    FormalEstatus,
    getParam,
    getQuery,
    SafeFormatDate,
    SafeFormatDateTime,
    SafeFormatTime,
    isValidFormikArrayField,
    isInvalidFormikArrayField,
    getErrorFormikFieldArray,
    FormalLatLng,
};