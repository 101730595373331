import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import BeatLoader from 'react-spinners/BeatLoader';

import axios from 'axios';
import { api } from '../../api';

class AxiosLoader extends Component {

    incrementar_contador_promesas(){
        // if(config.url.indexOf('autocomplete') === -1){
            var promises = this.state.promises;
            var nuevoConteo = this.state.conteo + 1;
            promises.push(1);
            this.setState({
                promises,
                conteo: nuevoConteo,
            });
        // }
    }

    decrementar_contador_promesas(){
        // if(response.config.url.indexOf('autocomplete') === -1){
            var promises = this.state.promises;
            var nuevoConteo = this.state.nuevoConteo > 0 ? (this.state.nuevoConteo - 1) : 0;
            promises.splice(1, 0);
            this.setState({
                promises,
                conteo: nuevoConteo,
            });
        // }
    }

    constructor(props){
        super(props);
        this.style = {
            
        }
        this.state = {
            promises: [],
            conteo: 0,
        };

        var requestInterceptor = (config) => {
            this.incrementar_contador_promesas();
            return config;
        };

        var responseInterceptor = (response) => {
            this.decrementar_contador_promesas();
            return response;
        };

        api.axios.interceptors.request.use(requestInterceptor, (error) => {
            this.decrementar_contador_promesas();
            return Promise.reject(error);
        });

        api.axios.interceptors.response.use(responseInterceptor, (error) => {
            this.decrementar_contador_promesas();
            return Promise.reject(error);
        });
        
        
        axios.interceptors.request.use(requestInterceptor, (error) => {
            this.decrementar_contador_promesas();
            return Promise.reject(error);
        });

        axios.interceptors.response.use(responseInterceptor, (error) => {
            this.decrementar_contador_promesas();
            return Promise.reject(error);
        });
    }

    render() {
        return (
            <LoadingOverlay
                styles={
                    {
                        textAlign: 'center',
                        overlay: (base) => ({
                            ...base,
                            zIndex: '99999',
                            position: 'fixed',
                            width: '100%',
                            height: '100%',
                        })
                    }
                }
                fadeSpeed={750}
                text="Espere por favor..."
                active={this.state.conteo > 0}
                spinner={<BeatLoader />}>
                {this.props.children}
            </LoadingOverlay>
        );
    }
}

export default AxiosLoader;