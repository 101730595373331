export default{

    'home': {
        path: '/',
        name: 'Home',
    },
    'login': {
        path: '/login',
        name: 'Login',
    },
    'dashboard': {
        path: '/dashboard',
        name: 'Inicio',
    },

    'administrador': {
        path: '/administrador',
        name: 'Administradores',
    },
    'administrador.nuevo': {
        path: '/administrador/nuevo',
        name: 'Nuevo administrador',
    },
    'administrador.editar': {
        path: '/administrador/editar/:id',
        name: 'Editar administrador',
    },
    
    'permiso': {
        path: '/permiso',
        name: 'Permisos',
    },
    'permiso.nuevo': {
        path: '/permiso/nuevo',
        name: 'Nuevo permiso',
    },
    'permiso.editar': {
        path: '/permiso/editar/:id',
        name: 'Editar permiso',
    },

    // catalogo
    
    'tipoevento': {
        path: '/tipo-evento',
        name: 'Tipos de evento',
    },
    'tipoevento.nuevo': {
        path: '/tipo-evento/nuevo',
        name: 'Nuevo tipo de evento',
    },
    'tipoevento.editar': {
        path: '/tipo-evento/editar/:id',
        name: 'Editar tipo de evento',
    },


    'cliente': {
        path: '/cliente',
        name: 'Clientes',
    },
    'cliente.nuevo': {
        path: '/cliente/nuevo',
        name: 'Nuevo cliente',
    },
    'cliente.editar': {
        path: '/cliente/editar/:id',
        name: 'Editar cliente',
    },

    'evento': {
        path: '/evento',
        name: 'Eventos',
    },
    'evento.nuevo': {
        path: '/evento/nuevo',
        name: 'Nuevo evento',
    },
    'evento.editar': {
        path: '/evento/editar/:id',
        name: 'Editar datos generales',
    },
    'evento.presentacion': {
        path: '/evento/presentacion/:id',
        name: 'Presentación',
    },
    'evento.invitacion': {
        path: '/evento/invitacion/:id',
        name: 'Invitaciones',
    },
    'evento.menu': {
        path: '/evento/menu/:id',
        name: 'Menú del evento',
    },

    'multimedia': {
        path: '/multimedia',
        name: 'Multimedia',
    }

}