import api from './api';

const tipoEventoService = {
    getList: () => {
        return api.get('/tipo-evento');
    },
    create: (data) => {
        return api.post('/tipo-evento', data)
    },
    update: (id, data) => {
        return api.put('/tipo-evento/'+id, data)
    },
    delete: (id) => {
        return api.delete('/tipo-evento/'+id)
    },
    getOne: (id) => {
        return api.get('/tipo-evento/'+id)
    },
};

export default tipoEventoService;